import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { ApiContext } from './ApiContext';
import './styles/fonts.css'
import './LoginPage.css'; // Assurez-vous que le chemin vers votre fichier CSS est correct

const LoginPage = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const { apiUrl } = useContext(ApiContext);

  const from = location.state?.from?.pathname || '/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(`${apiUrl}/api/password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('accessToken', data.access);
        login(data.access);
        navigate(from);
      } else {
        setError('Mauvais mot de passe, veuillez réessayer.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Une erreur s\'est produite. Si le problème persiste prévenez moi.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="ASJ" >Anne-Sophie et Jean</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mot de passe"
          />
          <button ttype="submit" className="button-elegant">Valider</button>
        </form>
        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default LoginPage;
