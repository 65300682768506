// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';



const AuthContext = createContext();
console.log('apiurl defined')
const apiUrl = 'https://api.mariageannesoetjean.fr'
//const apiUrl='http://localhost:8000'


export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  console.log('starting authprovider')
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        setIsAuthenticated(false);
        setIsLoading(false);
        return;
      }
      try {
        // Supposons que vous envoyiez une requête à votre API pour vérifier le token
        const response = await fetch(`${apiUrl}/api/verify-token/`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          localStorage.removeItem('accessToken');
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error during token verification:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    verifyToken();
  }, []);

  const login = (token) => {
    localStorage.setItem('accessToken', token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
