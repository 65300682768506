import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SlugForm.css'
import { ReactComponent as CorgIcon } from './VECTO_COULEURS_CORGI1.svg'; // Assurez-vous que le chemin est correct

const SlugForm = () => {
  const [slug, setSlug] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const encodedSlug = encodeURIComponent(slug); // Encoder le slug avant de rediriger
    console.log(`Encoded Slug: ${encodedSlug}`); // Ajoutez ce log pour vérifier l'encodage
    navigate(`/rsvp/${encodedSlug}`); // Redirige vers la page RSVP avec le slug encodé
  };

  return (
    <div className="slug-container">
      <div>
        <p className='slug-text'>
          Vous pouvez nous répondre sur ce site, en saisissant le "code accès personnel" situé sur votre carton d'invitation
        </p>
      </div>
      <div className="slug-box">
        <form onSubmit={handleSubmit}>
          <input 
            type="text"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            placeholder="Entrez votre code accès personnel"
          />
          <button type="submit" className="button-elegant">Valider</button>
        </form>
      </div>
      <CorgIcon className='corg'></CorgIcon>
    </div>
  );
};

export default SlugForm;
