import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RSVPForm from './RSVPForm';
import ProtectedRoute from './PasswordProtectedRoute';
import LoginPage from './LoginPage';
import WeddingPage from './WeddingPage';
import LoadingComponent from './LoadingComponent'
import Navbar from './NavBar'
import SlugForm from './SlugForm'
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { useAuth } from './AuthContext';
import PracticalInfoPage from './PracticalInfoPage'
import WeddingListPage from './WeddingList';
import Photos from './Photos';

import { ApiProvider } from './ApiContext';




function App() {
  



  console.log('reload App')
  const { isLoading } = useAuth();








  


  return (
    <Router>
      <div className="App">
      <Navbar />

        <Routes>
          <Route path="/rsvp/:householdSlug" element={<ApiProvider><RSVPForm /></ApiProvider>} />
          <Route path="/login" element={<ApiProvider><LoginPage/></ApiProvider>} />

        <Route 
        path="/wedding"
element={isLoading?
  (
      <LoadingComponent />
  ):
  (
    <ProtectedRoute>
      <WeddingPage />
    </ProtectedRoute>
  )
} 
        />
                <Route 
        path="/"
element={isLoading?
  (
      <LoadingComponent />
  ):
  (
    <ProtectedRoute>
      <WeddingPage />
    </ProtectedRoute>
  )
} 
        />
                <Route 
          path="/rsvp" 
          element={
            <ApiProvider>
            <ProtectedRoute>
<SlugForm/>          
</ProtectedRoute>
</ApiProvider>
          } 
        />

<Route 
        path="/infos_pratiques"
element={isLoading?
  (
      <LoadingComponent />
  ):
  (
    <ProtectedRoute>
      <PracticalInfoPage />
    </ProtectedRoute>
  )
} 
        />
<Route 
        path="/liste"
element={isLoading?
  (
      <LoadingComponent />
  ):
  (
    <ProtectedRoute>
      <WeddingListPage />
    </ProtectedRoute>
  )
} 
        />

<Route 
        path="/photos"
element={isLoading?
  (
      <LoadingComponent />
  ):
  (
    <ProtectedRoute>
      <Photos />
    </ProtectedRoute>
  )
} 
        />


          {/* Add other routes here */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
