import React from 'react';
import './WeddingList.css';
import { ReactComponent as Oiseaux } from './VECTO_COULEURS_OISEAUX1.svg'; // Assurez-vous que le chemin est correct


const WeddingListPage = () => {
  return (
    <div className='wlistcontainer'>
    <div className='wlistbox'>
      <h2 className='h2list'>Liste de Mariage</h2>
      <p className='plist'>Vous pouvez retrouver notre liste de mariage sous ce lien. Merci pour vos cadeaux et pour votre présence à nos côtés !</p>
      <a className='alist' href="https://www.millemercismariage.com/mariageannesoetjean/liste.html"  target="_blank" rel="noopener noreferrer">Voir la liste</a>
      <Oiseaux className='wazo1'></Oiseaux>

    </div>
    </div>

  );
};

export default WeddingListPage;
