import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import GuestInput from './GuestInput';
import { ApiContext } from './ApiContext';
import "./RSVPForm.css"

const RSVPForm = () => {
    const { apiUrl } = useContext(ApiContext);
    const navigate = useNavigate(); // Replaces useHistory
    const [personalMessage, setPersonalMessage] = useState('');
    const handlePersonalMessageChange = (e) => {
        setPersonalMessage(e.target.value);
    };
    const [feedback, setFeedback] = useState('');
    const { householdSlug } = useParams(); // Retrieve householdSlug from the URL
    const decodedHouseholdSlug = householdSlug.replace('&','et'); // Decoder le slug
    const [household, setHousehold] = useState({ email: '' });
    const [guests, setGuests] = useState([]);
    const [emailError, setEmailError] = useState('');
    const handleGuestChange = (guestId, fieldName, newValue) => {
        setGuests(guests.map(guest => {
            if (guest.id === guestId) {
                if (fieldName === 'chosen_response') {
                    const newChosenResponse = guest.invitation_type.response_option_list.find(option => option.id.toString() === newValue);
                    return {
                        ...guest,
                        chosen_response: newChosenResponse || guest.chosen_response // keep the old value if not found
                    };
                }
                return { ...guest, [fieldName]: newValue };
            }
            return guest;
        }));
    };
    const handleEmailChange = (e) => {
        setHousehold({ ...household, email: e.target.value });
        setEmailError('');
    };

    useEffect(() => {
        axios.get(`${apiUrl}/api/rsvp/${decodedHouseholdSlug}/`)
            .then(response => {
                setHousehold(response.data);
                setGuests(response.data.guests);
            })
            .catch(error => {
                console.error('Error updating RSVP', error);
                setFeedback('Erreur lors de l’envoi de votre réponse. Veuillez réessayer.');
                if (error.response && error.response.status === 404) {
                    setFeedback('Le code fourni n\'était pas valide.');
                    navigate('/rsvp/'); // Use navigate for redirection
                }
            });
    }, [decodedHouseholdSlug, apiUrl, navigate]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!household.email) {
            setEmailError('Nous avons besoin de votre email !');
            return;
        }
        const payload = {
            ...household,
            guests: guests.map(guest => ({
                ...guest,
                invitation_type_id: guest.invitation_type.id,
                chosen_response_id: guest.chosen_response ? guest.chosen_response.id : null
            })),
            personnal_message: personalMessage,
            has_responded: true
        };
        payload.guests.forEach(guest => {
            delete guest.invitation_type;
            delete guest.chosen_response;
        });
        axios.put(`${apiUrl}/household/${decodedHouseholdSlug}/`, payload)
            .then(response => {
                if (response.status === 200) {
                    setFeedback('Votre réponse a été envoyée avec succès !');
                } else {
                    setFeedback('Un problème est survenu lors de l’envoi de votre réponse.');
                }
            })
            .catch(error => {
                console.error('Error updating RSVP', error);
                setFeedback('Erreur lors de l’envoi de votre réponse. Veuillez réessayer.');
            });
    };

    return (
        <Container className='rsvpcontainer'>
            <Row className='rsvprow'>
                <Col>
                    <h1>Votre réponse</h1>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formHouseholdEmail">
                            <Form.Label>Votre adresse e-mail</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Votre email"
                                value={household.email}
                                onChange={handleEmailChange}
                                isInvalid={!!emailError}
                            />
                            <Form.Control.Feedback type="invalid">
                                {emailError}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <h2 className='rsvph2'>Les participants</h2>
                        {guests.map((guest) => (
                            <GuestInput key={guest.id} guest={guest} onGuestChange={handleGuestChange} />
                        ))}
                        <h2 className='rsvph2'>Message personnel</h2>
                        <Form.Group controlId="formPersonalMessage">
                            <Form.Label>Un petit message pour les mariés et leur famille si vous le souhaitez! (précisez aussi vos intolérances ou vos régimes alimentaires s'il y a lieu)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                value={personalMessage}
                                onChange={handlePersonalMessageChange}
                                maxLength={500}
                            />
                            <Form.Text muted>Maximum 500 caractères.</Form.Text>
                        </Form.Group>
                        <Button type="submit" className='button-primary'>
                            Envoyer votre réponse
                        </Button>
                        {feedback && <div className="feedback">{feedback}</div>}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default RSVPForm;
