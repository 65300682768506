import React, { useState } from 'react';
import './WeddingPage.css';
import PracticalInfoPage from './PracticalInfoPage';
import { ReactComponent as WeddingIcon } from './VECTO_COULEURS_OISEAUX2.svg'; // Assurez-vous que le chemin est correct
import { ReactComponent as CorgIcon } from './VECTO_COULEURS_CORGI2.svg'; // Assurez-vous que le chemin est correct
import { ReactComponent as ButterflIcon } from './VECTO_COULEURS_PAPILLION2.svg'; // Assurez-vous que le chemin est correct

const events = [
  { time: '14h30', title: 'Messe', location: 'Église Saint-Etienne du Val-d\'Izé', particle: ' à l\'', displayText: 'Église Saint-Etienne du Val-d\'Izé', url: 'https://www.google.com/maps/dir//Rue+de+L\'Eglise,+35450+Val-d\'Iz%C3%A9/@48.154917,-1.3821049,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x48092c5b8e600d25:0xe9828b9f4848ddc8!2m2!1d-1.3048853!2d48.1777387?entry=ttu' },
  { time: '16h', title: 'Cocktail', location: 'Château du Bois-Cornillé', particle:' au ', displayText: 'Bois-Cornillé', url: 'https://www.google.com/maps/dir//Ch%C3%A2teau+du+Bois-Cornill%C3%A9,+Cornille,+Val-d\'Iz%C3%A9/@48.1814487,-1.3817055,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x48092c457737382f:0x501570b266dd11d1!2m2!1d-1.2993045?2d48.1813637?entry=ttu' },
  { time: '19h', title: 'Dîner', location: 'Château du Bois-Cornillé', particle:' au ', displayText: 'Bois-Cornillé', url: 'https://www.google.com/maps/dir//Ch%C3%A2teau+du+Bois-Cornill%C3%A9,+Cornille,+Val-d\'Iz%C3%A9/@48.1814487,-1.3817055,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x48092c457737382f:0x501570b266dd11d1!2m2!1d-1.2993045?2d48.1813637?entry=ttu' },
  { time: '23h', title: 'Soirée', location: 'Château du Bois-Cornillé', particle:' au ', displayText: 'Bois-Cornillé', url: 'https://www.google.com/maps/dir//Ch%C3%A2teau+du+Bois-Cornill%C3%A9,+Cornille,+Val-d\'Iz%C3%A9/@48.1814487,-1.3817055,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x48092c457737382f:0x501570b266dd11d1!2m2!1d-1.2993045?2d48.1813637?entry=ttu' },
  { time: 'Dimanche 11h', title: 'Brunch', location: 'Château du Bois-Cornillé', particle:' au ', displayText: 'Bois-Cornillé', url: 'https://www.google.com/maps/dir//Ch%C3%A2teau+du+Bois-Cornill%C3%A9,+Cornille,+Val-d\'Iz%C3%A9/@48.1814487,-1.3817055,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x48092c457737382f:0x501570b266dd11d1!2m2!1d-1.2993045?2d48.1813637?entry=ttu' }
];

const locations = [
  { particle: 'à l\'', name: 'Église Saint-Etienne du Val-d\'Izé', address: 'rue de L\'Eglise, 35450 Val-d\'Izé', url: 'https://www.google.com/maps/dir//Rue+de+L\'Eglise,+35450+Val-d\'Iz%C3%A9/@48.154917,-1.3821049,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x48092c5b8e600d25:0xe9828b9f4848ddc8!2m2!1d-1.3048853!2d48.1777387?entry=ttu' },
  { particle: 'au ', name: 'Château du Bois-Cornillé', address: '17 rue du Château, 35450 Val-d\'Izé', url: 'https://www.google.com/maps/dir//Ch%C3%A2teau+du+Bois-Cornill%C3%A9,+Cornille,+Val-d\'Iz%C3%A9/@48.1814487,-1.3817055,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x48092c457737382f:0x501570b266dd11d1!2m2!1d-1.2993045?2d48.1813637?entry=ttu' }
];

const WeddingPage = () => {
  const [highlight, setHighlight] = useState(null);

  const handleEventClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className='apage'>
      <div className='container'>
        <div className="left-column">
          <div className='header-programme'>
            <h1 className='welcome'>Bienvenue à notre mariage</h1>
            <CorgIcon className='corgi' />
          </div>
          
          <section className='sectioninfo'>
            <div className='pannel1'>
              <ButterflIcon className='papillon' />
            </div>

            <div className='pannel2'>
              <h2 className='prog'>Le programme</h2>
              <ul>
                {events.map(event => (
                  <li
                    key={event.time}
                    onMouseEnter={() => setHighlight(event.location)}
                    onMouseLeave={() => setHighlight(null)}
                    className={`clickable ${highlight === event.location ? 'highlight' : ''}`}
                    onClick={() => handleEventClick(event.url)}
                  >
                    <strong>{event.time} : </strong> {event.title} {event.particle} {event.displayText}
                  </li>
                ))}
              </ul>
            </div>

            <div className='pannel3'>
              <WeddingIcon className='wazo' />
            </div>
          </section>
        </div>

        <div className="right-column">
          <section className="locations-list">
            <div className='leslieux'>
              <h2>Les lieux</h2>
              <ul>
                {locations.map(loc => (
                  <li
                    key={loc.name}
                    onMouseEnter={() => setHighlight(loc.name)}
                    onMouseLeave={() => setHighlight(null)}
                    className={`clickable ${highlight === loc.name ? 'highlight' : ''}`}
                    onClick={() => handleEventClick(loc.url)}
                  >
                    {loc.name} ({loc.address})
                  </li>
                ))}
              </ul>
            </div>
            <div className="map">
              <PracticalInfoPage highlight={highlight} />
            </div>
            <div className='lesfleurs'>
              {/* <FlowerIcon className='imagefleur' /> */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default WeddingPage;
