import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavBar.css'; // Assurez-vous que le chemin vers votre fichier CSS est correct

import './styles/fonts.css'

const NavbarComponent = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className='beautiful-navbar'>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>AS & J</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/wedding">
              <Nav.Link>Accueil</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/liste">
              <Nav.Link>Liste de Mariage</Nav.Link>
            </LinkContainer>

            <LinkContainer to="/rsvp">
              <Nav.Link>RSVP</Nav.Link>
            </LinkContainer>


            <LinkContainer to="/photos">
              <Nav.Link>Photos</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
