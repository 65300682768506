import React from 'react';
import './WeddingList.css';
import { ReactComponent as Fleur } from './VECTO_COULEURS_PAPILLION1.svg'; // Assurez-vous que le chemin est correct


const Photos = () => {
  return (
    <div className='wlistcontainer'>
    <div className='wlistbox'>
      <h2 className='h2list'>Photos</h2>
      <p className='plist'>Après le jour J, vous pourrez retrouver les photos sur cette page</p>
s      <Fleur className='wazo1'></Fleur>

    </div>
    </div>

  );
};

export default Photos;
