import React from 'react';

export const ApiContext = React.createContext();

export const ApiProvider = ({ children }) => {
  console.log(process.env)
  //const apiUrl = process.env.REACT_APP_API_URL;
  //const apiUrl ='http://localhost:8000'
  const apiUrl = 'https://api.mariageannesoetjean.fr'

  console.log('dans le provider on a ');
  console.log(apiUrl);

  return (
    <ApiContext.Provider value={{ apiUrl }}>
      {children}
    </ApiContext.Provider>
  );
};
