import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const svgIcons = {
  'Église Saint-Etienne du Val-d\'Izé': './image_1715371402796_xhivl.svg',
  'Château du Bois-Cornillé': './image_1715371132368_j5vr0t.svg'
};

const locations = {
  'Église Saint-Etienne du Val-d\'Izé': {
    coords: [48.177909398833364, -1.3048751489967545],
    url: 'https://www.google.com/maps/dir//Rue+de+L\'Eglise,+35450+Val-d\'Iz%C3%A9/@48.154917,-1.3821049,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x48092c5b8e600d25:0xe9828b9f4848ddc8!2m2!1d-1.3048853!2d48.1777387?entry=ttu'
  },
  'Château du Bois-Cornillé': {
    coords: [48.181542542981276, -1.2992830422529096],
    url: 'https://www.google.com/maps/dir//Ch%C3%A2teau+du+Bois-Cornill%C3%A9,+Cornille,+Val-d\'Iz%C3%A9/@48.1814487,-1.3817055,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x48092c457737382f:0x501570b266dd11d1!2m2!1d-1.2993045!2d48.1813637?entry=ttu'
  }
};

const PracticalInfoPage = ({ highlight }) => {
  const mapRef = useRef(null);
  const markersRef = useRef({});

  useEffect(() => {
    const map = L.map('map').setView([48.181542542981276, -1.2992830422529096], 15);
    mapRef.current = map;

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    // Initialize markers with specific SVG icons and click event listener
    Object.entries(locations).forEach(([location, { coords, url }]) => {
      markersRef.current[location] = L.marker(coords, {
        icon: L.icon({
          iconUrl: svgIcons[location],
          iconSize: [25, 41], // Default size
          iconAnchor: [12, 41] // Default anchor
        })
      }).addTo(map).on('click', () => {
        window.open(url, '_blank');
      });
    });

    return () => map.remove();
  }, []);

  useEffect(() => {
    // Update icon size and anchor based on highlight
    Object.keys(markersRef.current).forEach(location => {
      const marker = markersRef.current[location];
      const isHighlighted = location === highlight;
      const newSize = isHighlighted ? [40, 66] : [30, 49]; // Larger size if highlighted
      const newAnchor = isHighlighted ? [17, 57] : [15, 41]; // Adjust anchor if highlighted
      marker.setIcon(L.icon({
        iconUrl: svgIcons[location],
        iconSize: newSize,
        iconAnchor: newAnchor
      }));
    });
  }, [highlight]);

  return <div id="map" style={{ height: '300px', width: '100%' }}></div>;
};

export default PracticalInfoPage;
